import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpOrderEntity from "../entity";
import ErpOrderGeneral from "./show/general";
import ErpCustomerEntity from "../../customer/entity";
import UtilsErp from "../../../../utils/erp";

export default class ErpOrderNew extends DefaultController {
    private customerAddModal: any;
    private cart: any
    private customerId = null

    async init() {
        this.customerAddModal = bootstrap.Modal.getOrCreateInstance((document.querySelector("#newErpOrderCustomerModal") as HTMLElement));
        this.entity = "erp/orders"
        this.childs = {
            general: new ErpOrderGeneral(this, "new")
        }
        await Utils.createSelects(this.data, (elem: any) => {
            return this.onSelectGetFilterOptions(elem)
        }, (data: any, entity: any, element: any) => {
            return this.onSelectFilterResults(data, entity, element)
        });

        this.bindListeners();

        // @ts-ignore
        this.childs.general.update({lineItems: []})
        const d = await UtilsErp.initOrder();
        this.cart = d.data

        await this.checkParams()
        await Utils.hideLoader();
    }

    protected async addLineItem(data: any) {
        const r = await UtilsErp.addLineItemOrder(data, this.customerId, this.cart.token);
        (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled");
        return r;
    }

    protected async addLinePallet(data: any, orderId = null) {
        const r = await UtilsErp.addPalletOrder(data, this.cart.token, orderId);
        (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled");
        return r;
    }

    protected async removeLineItem(data: any) {
        const r = await UtilsErp.removeLineItemOrder(data, this.cart.token);
        (document.querySelector("#erp_order_save") as HTMLButtonElement).removeAttribute("disabled");
        return r;
    }

    protected getEntityData(elem: any) {
        return ErpOrderEntity.getEntityData(elem)
    }

    protected getCustomerEntityData(elem: any) {
        return ErpCustomerEntity.getEntityData(elem)
    }

    bindListeners() {
        jQuery("#new_erp_order_line_item_product_id").on("select2:ajax_done", async (e, data: any) => {
            console.log("data", data)
            if (data && data.status === 200 && data.data.length === 1 && data.data[0].is_serial_match === true) {
                const d: any = data.data[0];
                setTimeout(async () => {
                    (document.querySelector("#erp_order_line_item_serials") as HTMLInputElement).value = d.serials[d.serial_match_index];
                    (document.querySelector("#erp_order_line_item_unitPrice") as HTMLInputElement).value = d.product.price.gross;
                    (document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value = "1";

                    const option = new Option(d.product.name, d.product.uuid.toLowerCase(), true, true);
                    jQuery('#new_erp_order_line_item_product_id').append(option).trigger({
                        type: 'select2:select',
                        params: {
                            data: {
                                data: {
                                    ...d,
                                    serials: d.serials[d.serial_match_index],
                                    ids: [d.ids[d.serial_match_index]],
                                }
                            }
                        }
                    }).trigger('change');
                    jQuery('#new_erp_order_line_item_product_id').select2('close');
                    //@ts-ignore
                    await this.childs.general.submitLineItem();
                }, 200);
            }
        });

        jQuery("#new_erp_order_line_item_product_id").on("select2:select", async (e, data: any) => {
            await this.addWarehouseInfo(e);
        });

        (document.querySelector("#erp_order_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {

            await Utils.showLoader()
            const r = await UtilsErp.saveOrder(this.cart.token)
            if (r && r.data && r.data.id && r.status === 200) {
                //@ts-ignore
                const ticketUuid = document.querySelector("#erp_order_ticket_uuid")?.value as string
                const externalTicket = document.querySelector("#erp_order_external_ticket_name")?.value as string
                await Utils.entity.upsert({order_uuid: r.data.id, ticket_uuid: ticketUuid, external_ticket: externalTicket}, 'ticket_order_maps')
                this.toastr.success(`${Utils.translate('erp.order.name')} ${Utils.translate('generic.messages.duplicated')}`, `${Utils.translate('generic.success')}`)
                setTimeout(() => {
                    document.location.href = `/${(window as any).currentLocale}/erp/orders/${r.data.id}`
                }, 1000)
            }
        });

        //@ts-ignore
        jQuery("#order_customer_id").on("select2:select", async (e: any) => {
            const customerData = e.params.data.data
            const addresses = customerData.addresses
            jQuery('#order_customer_address_id').empty().trigger('change');
            jQuery('#order_customer_address_id').append(new Option("Addresse wählen!", "NONE", false, false));
            addresses.forEach((address: any) => {
                if (address.customFields && address.customFields.fltools_customer_location_uuid) {
                    const name = customerData.customerLocations.filter((l: any) => {
                        return l.uuid === address.customFields.fltools_customer_location_uuid
                    })[0]
                    const data = {
                        id: address.uuid,
                        text: `${name ? name.name : `${address.firstName} ${address.lastName}\n${address.street}\n${address.zipcode} ${address.city}`}`
                    };

                    const newOption = new Option(data.text, data.id, false, false);
                    jQuery('#order_customer_address_id').append(newOption);
                }
            })
            jQuery('#order_customer_address_id').trigger("change");
            jQuery('#order_customer_address_id').removeAttr("disabled")

            console.log("customerData", customerData)
            this.customerId = customerData.sw_id
        })

        //@ts-ignore
        jQuery("#order_customer_address_id").on("select2:select", async (e: any) => {
            const generalTab = document.querySelector(".erp_order_new_row #general") as HTMLElement;
            const data = e.target.value
            generalTab.style.opacity = "0.5";
            generalTab.style.pointerEvents = "none";
            if (data && data !== "NONE") {
                await UtilsErp.customerOrder(this.customerId, data, this.cart.token)
                generalTab.style.opacity = "1";
                generalTab.style.pointerEvents = "all";
            }
        })

        jQuery("body").delegate(".line_item_warehouse_map", "keyup change", (e) => {
            e.preventDefault();
            const target = e.target;
            const maxQuantity = parseInt(target.getAttribute("data-max-quantity"));
            const currentQuantity = parseInt(target.value);
            const fullQuantity = parseInt((document.querySelector("#erp_order_line_item_quantity") as HTMLInputElement).value);
            if (maxQuantity < currentQuantity) {
                target.value = String(maxQuantity);
            }
            let usedQuantity = 0;
            const inputs = document.querySelectorAll(".line_item_warehouse_map");
            inputs.forEach((input: any) => {
                usedQuantity += parseInt(input.value)
            })
            const saveBtn = document.querySelector("#erp_line_item_add_save_button") as HTMLButtonElement
            if (fullQuantity === usedQuantity) {
                saveBtn.removeAttribute("disabled");
            } else {
                saveBtn.setAttribute("disabled", "disabled");
            }
        })
    }

    async addWarehouseInfo(data: any) {
        if (data.params.data.id) {
            const r = await UtilsErp.stockInfo(data.params.data.id.toLowerCase());
            // @ts-ignore
            const stockInfo = r.data.filter(d => d.customer.uuid && d.customer.uuid.toLowerCase() === this.customerId.toLowerCase());
            console.log("AAA", r.data, stockInfo)
            let html = '';
            const tbody = document.querySelector("#erp_order_line_item_add_warehouse_list_table tbody") as HTMLElement;
            tbody.innerHTML = "";
            stockInfo.forEach((stock: any) => {
                let name = `${stock.warehouse.name}, ${stock.warehouse_location.name}`
                if (stock.warehouse_location_pallet.uuid) {
                    name += `, ${stock.warehouse_location_pallet.name}`
                }
                let options = ``
                stock.serials.forEach((sn: string) => {
                    console.log(sn[0])
                    if (sn[0] && stock.serials_reserved.flat().indexOf(sn[0]) === -1) {
                        options += `<option value='${sn[0]}'>${sn[0]}</option>`
                    }
                })

                let availableStock = stock.stock - stock.stock_reserved
                if (availableStock > 0) {

                    html += `<tr>
<td style="width: 100%;">
<div class="row align-items-center">
 <div class="col-12 mb-1" style="font-size:12px;">${name}</div>
 <div class="col-6 mb-1">
 <input class="form-control line_item_warehouse_map" value="0" type="number" min="0" max="${availableStock}" data-max-quantity="${availableStock}" data-warehouse-id="${stock.warehouse.uuid}" data-warehouse-location-id="${stock.warehouse_location.uuid}" ${stock.warehouse_location_pallet.uuid ? `data-warehouse-location-pallet-id="${stock.warehouse_location_pallet.uuid}"` : ``} />

</div>
 <div class="col-6 mb-1"> / ${availableStock}</div>
 <div class="col-12">
 <label class="form-label">Serials: </label>
 <select class="form-select" multiple>
 ${options}
</select>
 </div>
</td>
</tr>`
                }
            })
            tbody.innerHTML = html;
            console.log("Stock Info", stockInfo)
        }
    }

    async checkParams() {
        const uri = new URL(document.location.href)
        const params = uri.searchParams

        if (params.get("ticket_id")) {
            (document.querySelector("#erp_order_ticket_uuid") as HTMLInputElement).value = params.get("ticket_id") || ""
        }
        if (params.get("customer_id")) {
            const customer = await Utils.entity.get((params.get("customer_id") as string), "customers");
            const $newOptionCustomer = jQuery("<option selected='selected'></option>").val(customer.data[0].uuid).text(customer.data[0].name)
            jQuery("#order_customer_id").append($newOptionCustomer).trigger('change')
            jQuery('#order_customer_address_id').removeAttr("disabled")
            this.customerId = customer.data[0].sw_id
            if (params.get("customer_location_id")) {
                let matchingAddress = null;
                const locations = customer.data[0].addresses
                jQuery('#order_customer_address_id').empty().trigger('change');
                locations.forEach((address: any) => {
                    if (address.customFields && address.customFields.fltools_customer_location_uuid) {
                        const name = customer.data[0].customerLocations.filter((l: any) => {
                            return l.uuid === address.customFields.fltools_customer_location_uuid
                        })[0]
                        const data = {
                            id: address.uuid,
                            text: `${name ? name.name : `${address.firstName} ${address.lastName}\n${address.street}\n${address.zipcode} ${address.city}`}`
                        };

                        const newOption = new Option(data.text, data.id, params.get("customer_location_id") === address.customFields.fltools_customer_location_uuid, params.get("customer_location_id") === address.customFields.fltools_customer_location_uuid);
                        jQuery('#order_customer_address_id').append(newOption);
                        if (params.get("customer_location_id") === address.customFields.fltools_customer_location_uuid) {
                            matchingAddress = address;
                        }
                    }
                })
                jQuery('#order_customer_address_id').trigger("change");
                jQuery('#order_customer_address_id').removeAttr("disabled");
                if (matchingAddress) {
                    // @ts-ignore
                    await UtilsErp.customerOrder(this.customerId, matchingAddress.id, this.cart.token)
                    const generalTab = document.querySelector(".erp_order_new_row #general") as HTMLElement;
                    generalTab.style.opacity = "1";
                    generalTab.style.pointerEvents = "all";
                }
            } else {
                console.log(customer.data[0])
                //@ts-ignore
                jQuery("#order_customer_id").trigger({
                    type: 'select2:select',
                    params: {
                        data: {
                            data: customer.data[0]
                        }
                    }
                });
            }
        }
    }

    onSelectGetFilterOptions(elem: any) {
        console.log("eh", elem)
        if (elem.getAttribute("id") === "new_erp_order_line_item_product_id") {
            return {
                customer_id: this.customerId
            }
        } else if (elem.getAttribute("id") === "erp_pallet_warehouse_location_pallet_id") {
            return {
                warehouse_id: (document.querySelector("#erp_pallet_warehouse_id option:checked") as HTMLSelectElement)?.value
            }
        }
        return {}
    }
}